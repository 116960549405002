<template >
  <div>
    <div class="row">
      <div class="col-md-12">
        <v-card v-if="selected == null" style="margin-bottom: 30px">
          <v-card-title>
            New Independent Contractors
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            @click:row="clicked"
            :headers="headers"
            :items="customers"
            :search="search"
            style="cursor: pointer;"
          ></v-data-table>
        </v-card>

        <div v-if="selected">
          <b-card class="mt-3" header="Info Order">
            <b-row>
              <b-col>
                <b-form-group id="input-group-2" label="Name:" label-for="i1">
                  <b-form-input id="i1" v-model="selected.name" disabled></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group id="input-group-2" label="Email:" label-for="i2">
                  <b-form-input id="i2" v-model="selected.email" disabled></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group id="input-group-2" label="Company:" label-for="i3">
                  <b-form-input id="i3" v-model="selected.company_name" disabled></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <label>Avatar</label>
                <br />
                <img :src="selected.avatar_path" style="width: 100%" />
              </b-col>
              <b-col>
                <label>Picture ID</label>
                <br />
                <img :src="selected.file_identity" style="width: 100%" />
              </b-col>
              <b-col>
                <label>License</label>
                <br />
                <img :src="selected.file_licence" style="width: 100%" />
              </b-col>
              <b-col>
                <label>W9 Form</label>
                <br />
                <img :src="selected.file_form_service" style="width: 100%" />
              </b-col>
              <b-col>
                <label>Business Insurence</label>
                <br />
                <img :src="selected.file_seguro" style="width: 100%" />
              </b-col>
            </b-row>

            <b-button
              type="button"
              @click="selected = null"
              variant="danger"
              style="margin-right: 20px;"
            >RETURN</b-button>
            <b-button
              type="button"
              @click="approve(selected.id)"
              variant="primary"
              style="margin-right: 20px;"
            >APPROVE</b-button>
            <b-button
              type="button"
              @click="recuse(selected.id)"
              variant="danger"
              style="margin-right: 20px;"
            >RECUSE</b-button>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "new-independent-contractor",
  methods: {
    clicked(value) {
      this.selected = value;
      console.log(this.selected);
    },

     approve(id){
    fetch('https://cleancity.krueltech.com/dev/api/api.php?action=approveNewInd&id='+id)
      .then(window.location.reload()).catch(console.log)
  } ,

  recuse(id){
    fetch('https://cleancity.krueltech.com/dev/api/api.php?action=refuseNewInd&id='+id)
      .then(window.location.reload()).catch(console.log)
  }
  },
  data() {
    return {
      customers: [],
      worker: [],
      workerBackup: [],
      orderAtual: null,
      fields: ["name", "city", "address", "type"],
      form: {
        email: "",
        name: "",
        registrationDate: "",
        food: null,
        checked: []
      },
      search: "",
      search2: "",
      selected: null,
      headers: [
        { text: "ID", value: "id" },
        { text: "Name", value: "name" },
        { text: "Email", value: "email" },
        { text: "Company Name", value: "company_name" },
        { text: "Phone", value: "phone" },
        { text: "Address", value: "address" }
      ]
    };
  },
  mounted() {
    var auth = localStorage.getItem("name");
    if(auth != null && auth != 'undefined'){
        console.log('existe')
    }else{
        console.log('nao existe')
        this.$router.push({ name: "login" });
    }
    
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vuetify", route: "alerts" },
      { title: "Tables", route: "simple-tables" },
      { title: "Data Tables" }
    ]);

    fetch("https://cleancity.krueltech.com/dev/api/api.php?action=readNewIndependent")
      .then(res => res.json())
      .then(data => {
        this.customers = data.worker;
        //console.log('RES: '+ JSON.stringify(data.independent));
      })
      .catch(console.log);
  }
};
</script>
